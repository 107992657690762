// import { Buffer } from "buffer";
import { addMetadata as addPngMetadata, getMetadata as getPngMetadata } from "meta-png"; // to add/get metadata to the png file
import { encode as pngEncode } from "fast-png"; // to create the 1x1 png file that holds the metadata

/* global console */

export interface ProjectMetadata {
  projectname: string;
  projecturl: string;
  tags: string[];
}
// apparently these don't work reliably in nodeJS, let's rewrite them manually
//
// function isValidBase64(str: string): boolean {
//   try {
//     Buffer.from(str, "base64").toString();
//     return true;
//   } catch (e) {
//     return false;
//   }
// }

// export function dataURLToArrayBuffer(dataURL: string): ArrayBuffer {
//   const base64 = getBase64FromDataURL(dataURL);
//   return Buffer.from(base64, "base64").buffer;
// }

// export function dataURLToString(dataURL: string): string {
//   const base64 = getBase64FromDataURL(dataURL);
//   return Buffer.from(base64, "base64").toString();
// }

// export function stringToDataUrl(string: string): string {
//   return "data:text/plain;base64," + stringToB64(string);
// }

// export function stringToB64(string: string): string {
//   return Buffer.from(string).toString("base64");
// }

// export function uint8ArrayToB64(uint8Array: Uint8Array): string {
//   return Buffer.from(uint8Array).toString("base64");
// }

const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

export function extractImgSrc(htmlString: string): string | null {
  // Regular expression to match the src attribute in an img tag
  const regex = /<img[^>]+src="([^">]+)"/;
  const match = htmlString.match(regex);

  // If a match is found, return the content of the src attribute
  if (match) {
    return match[1];
  }

  // If no match is found, return null
  return null;
}

function isValidBase64(str: string): boolean {
  try {
    base64Decode(str);
    return true;
  } catch (e) {
    return false;
  }
}

export function dataURLToArrayBuffer(dataURL: string): ArrayBuffer {
  const base64 = getBase64FromDataURL(dataURL);
  const binaryString = base64Decode(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes.buffer;
}

export function dataURLToString(dataURL: string): string {
  const base64 = getBase64FromDataURL(dataURL);
  return base64Decode(base64);
}

export function stringToDataUrl(string: string): string {
  return "data:text/plain;base64," + base64Encode(string);
}

export function stringToB64(string: string): string {
  return base64Encode(string);
}

export function uint8ArrayToB64(uint8Array: Uint8Array): string {
  let binaryString = "";
  for (let i = 0; i < uint8Array.length; i++) {
    binaryString += String.fromCharCode(uint8Array[i]);
  }
  return base64Encode(binaryString);
}

function base64Decode(base64: string): string {
  let str = "";
  let currentByte = 0;
  let encoded1, encoded2, encoded3, encoded4;

  for (let i = 0; i < base64.length; i++) {
    encoded1 = chars.indexOf(base64[i++]);
    encoded2 = chars.indexOf(base64[i++]);
    encoded3 = chars.indexOf(base64[i++]);
    encoded4 = chars.indexOf(base64[i]);

    currentByte = (encoded1 << 2) | (encoded2 >> 4);
    str += String.fromCharCode(currentByte);

    if (encoded3 !== -1) {
      currentByte = ((encoded2 & 15) << 4) | (encoded3 >> 2);
      str += String.fromCharCode(currentByte);
    }
    if (encoded4 !== -1) {
      currentByte = ((encoded3 & 3) << 6) | encoded4;
      str += String.fromCharCode(currentByte);
    }
  }

  return str;
}

function base64Encode(str: string): string {
  let output = "";
  let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
  let i = 0;

  while (i < str.length) {
    chr1 = str.charCodeAt(i++);
    chr2 = str.charCodeAt(i++);
    chr3 = str.charCodeAt(i++);

    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;

    if (isNaN(chr2)) {
      enc3 = enc4 = 64;
    } else if (isNaN(chr3)) {
      enc4 = 64;
    }

    output = output + chars.charAt(enc1) + chars.charAt(enc2) + chars.charAt(enc3) + chars.charAt(enc4);
  }

  return output;
}

export function readMetadataFromImage(imgSrc: string): any {
  console.log("readMetadataFromImage called");
  let arrayBuffer;
  console.log("imgSrc:", imgSrc);
  arrayBuffer = dataURLToArrayBuffer(imgSrc);
  console.log("arrayBuffer:", arrayBuffer);
  const uint8Array = new Uint8Array(arrayBuffer);
  console.log("uint8Array:", uint8Array);
  try {
    const description = getPngMetadata(uint8Array, "Description");
    if (description) {
      console.log("Extracted Description:", description);
      if (isDataURL(description) || isValidBase64(description)) {
        return JSON.parse(dataURLToString(description));
      }
    } else {
      console.log("No Description metadata found in the image.");
    }
  } catch (error) {
    console.log("Error reading metadata from image:", error);
  }
}

function getBase64FromDataURL(dataURL: string): string {
  const dataURLParts = dataURL.split(",");
  // console.log("dataURLParts:", JSON.stringify(dataURLParts));
  return dataURLParts.pop();
}

function isDataURL(str: string, contentType: string = null): boolean {
  const dataURLPattern = /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*$/;

  if (!dataURLPattern.test(str)) {
    return false;
  }

  if (contentType) {
    const match = str.match(dataURLPattern);
    if (match && match[1] === contentType) {
      return true;
    }
    return false;
  }

  return true;
}

export function createPngWithMetadata(data): string {
  const png = addPngMetadata(
    pngEncode({
      width: 1,
      height: 1,
      depth: 8,
      channels: 4,
      data: new Uint8Array([0, 0, 0, 0]),
    }),
    "Description",
    stringToB64(JSON.stringify(data))
  );

  const base64EncodedImage = uint8ArrayToB64(png);
  return "data:image/png;base64," + base64EncodedImage;
}

export function stripOdataAndFlatten(inputObj: any): any[] {
  if (!inputObj.value || !Array.isArray(inputObj.value)) {
    throw new Error("Expected an object with a 'value' key containing an array.");
  }

  return inputObj.value.map((item: any) => {
    const strippedItem: any = {};
    for (const key in item) {
      // Ignore keys with '@odata' in them
      if (!key.includes("@odata")) {
        if (key === "fields" && typeof item[key] === "object") {
          // Flatten fields
          for (const subKey in item[key]) {
            if (!subKey.includes("@odata")) {
              strippedItem[subKey] = item[key][subKey];
            }
          }
        } else {
          strippedItem[key] = item[key];
        }
      }
    }
    return strippedItem;
  });
}

export function validateMetadata(metadata) {
  if (metadata && metadata.projectname && metadata.projecturl && metadata.tags && metadata.tags.length > 0) {
    return true;
  }
  return false;
}
